// import { getjQuery } from './util/index.js'
import Data from './dom/data.js'
import SelectorEngine from './dom/selector-engine.js'
import { getUID, makeArray } from './util/index.js'
import Manipulator from './dom/manipulator.js'

const NAME = 'label-for'
const VERSION = '1.0.0'
const DATA_KEY = 'fb.label-for'

const Default = {
  parent: 'div'
}

class LabelFor {
  constructor(element, config) {
    this._element = element
    this._label = SelectorEngine.next(this._element, 'label')[0] || SelectorEngine.closest(this._element, 'label')

    if (this.labelCheck()) {
      return
    }

    this._config = this._getConfig(config)
    this._parent = SelectorEngine.closest(this._element, '[data-check-name]') || SelectorEngine.closest(this._element, this._config.parent)
    this._config.type = this._element.getAttribute('type')
    this._config.name = this._parent.getAttribute('data-check-name') || getUID(`${this._config.type}-`)

    // 인스턴스가 다중으로 설정될 경우 element.key 등록 안됨. 이슈해결 필요
    // Data.setData(element, DATA_KEY, this)
    this.init()
  }

  // Getters

  static get VERSION() {
    return VERSION
  }

  static get Default() {
    return Default
  }

  init() {
    const els = SelectorEngine.find('input', this._parent)
    const { name, type } = this._config

    makeArray(els).forEach((el, i) => {
      const n = ++i
      const label = SelectorEngine.next(el, 'label')[0] || SelectorEngine.closest(el, 'label')
      el.setAttribute('id', `${name}-${n}`)
      label.setAttribute('for', `${name}-${n}`)
      if (type === 'radio') {
        el.setAttribute('name', name)
      }
    })
  }

  dispose() {
    Data.removeData(this._element, DATA_KEY)
    this._element = null
  }

  labelCheck() {
    const attrId = this._element.getAttribute('id')
    const attrFor = this._label.getAttribute('for')

    return typeof attrId === 'string' && typeof attrFor === 'string' && attrId === attrFor
  }

  // private
  _getConfig(config) {
    config = {
      ...this.constructor.Default,
      ...Manipulator.getDataAttributes(this._element),
      ...typeof config === 'object' && config ? config : {}
    }

    return config
  }

  // Static
  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }
}

makeArray(document.querySelectorAll('input[type="radio"], input[type="checkbox"]')).forEach(el => {
  return new LabelFor(el)
})

export default LabelFor
