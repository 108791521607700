import { makeArray, isVisible, typeCheckConfig } from './util/index.js'
import Data from './dom/data.js'
import EventHandler from './dom/event-handler.js'
import SelectorEngine from './dom/selector-engine.js'
import Manipulator from './dom/manipulator.js'

class Scrollbar {
  constructor(element, config) {
    if (!isVisible(element)) {
      return
    }

    this._element = element
    this.scrollWrapper = document.createElement('div')
    this._config = this._getConfig(config)
    this.maxScrollValue = null
    this.scrollValue = null
    this.ticking = false
    this.lastPage = {
      x: 0,
      y: 0
    }

    this.scrollOuter = this._config.outer ? this.scrollWrapper : this._element
    this.scrollInner = this._config.outer ? this._element : this.scrollWrapper

    if (this._config.direction === 'vertical') {
      this.scrollDirection = 'scrollTop'
      this.axis = 'height'
      this.scrollAxis = 'scrollHeight'
    } else {
      this.scrollDirection = 'scrollLeft'
      this.axis = 'width'
      this.scrollAxis = 'scrollWidth'
    }

    this.draggle = false
    this.mouseEntered = false
    this.resized = false

    setTimeout(() => {
      this.rect = this._element.getBoundingClientRect()

      const sizeProperty = this._config.direction === 'vertical' ? 'offsetHeight' : 'offsetWidth'
      if (this._element[sizeProperty] >= this._element[this.scrollAxis]) {
        return
      }

      // this._element style
      const {
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft
      } = window.getComputedStyle(this._element)

      this._element.style.padding = 0

      this.scrollInner.setAttribute('tabindex', 0)
      this.scrollOuter.removeAttribute('tabindex')
      // createElement
      this.scrollWrapper.classList.add('scroll-wrapper')
      this.scrollWrapper.style.paddingTop = paddingTop
      this.scrollWrapper.style.paddingRight = paddingRight
      this.scrollWrapper.style.paddingBottom = paddingBottom
      this.scrollWrapper.style.paddingLeft = paddingLeft

      this.scrollbarInner = document.createElement('div')
      this.scrollbarInner.classList.add('scrollbar__inner')

      this.scrollbar = document.createElement('div')
      this.scrollbar.classList.add('scrollbar')
      this.scrollbar.classList.add(`scrollbar--${this._config.direction}`)
      this.scrollbar.appendChild(this.scrollbarInner)

      this.scrollOuter.style.overflow = 'hidden'
      this.scrollOuter.style.position = 'relative'

      if (this._config.direction === 'vertical') {
        this.scrollInner.style.overflowY = 'auto'
        this.scrollbar.style.transform = `translateX(${this._config.scrollOffset}px)`
        this.scrollbar.style.top = `${this._config.scrollSpacing / 2}px`
        this.scrollbar.style.bottom = `${this._config.scrollSpacing / 2}px`
      } else {
        this.scrollInner.style.overflowX = 'auto'
        this.scrollbar.style.transform = `translateY(${this._config.scrollOffset}px)`
        this.scrollbar.style.left = `${this._config.scrollSpacing / 2}px`
        this.scrollbar.style.right = `${this._config.scrollSpacing / 2}px`
      }

      if (this._config.outer) {
        const parent = SelectorEngine.parents(this._element, this._config.parentSelector)[0]
        parent.insertBefore(this.scrollWrapper, this._element)
        this.scrollWrapper.appendChild(this._element)
      } else {
        const childrenEl = SelectorEngine.children(this._element, '*')
        childrenEl.forEach(el => {
          this.scrollWrapper.appendChild(el)
        })
        this._element.style.overflow = 'hidden'
        this._element.appendChild(this.scrollWrapper)
      }

      Data.setData(this._element, this.constructor.DATA_KEY, this)

      this.init()
      this.addEventLitener()
    }, 0)
  }

  init() {
    const { scrollInner, scrollOuter, scrollbar, scrollbarInner, axis, scrollAxis } = this
    let scrollPercent = 0

    this.rect = this._element.getBoundingClientRect()

    scrollInner.style[axis] = Math.floor(this.rect[axis]) + 'px'
    scrollPercent = Math.floor(this.rect[axis] * 100 / scrollInner[scrollAxis])

    if (scrollPercent === 100 || !this.rect[axis]) {
      if (scrollOuter.querySelector('.scrollbar')) {
        scrollOuter.removeChild(scrollbar)
        scrollInner.removeAttribute('tabindex')

        if (this._config.paddingSize) {
          const paddingDirection = this._config.direction === 'vertical' ? 'paddingRight' : 'paddingBottom'
          this.scrollInner.style[paddingDirection] = 0
        }
      }

      return
    }

    scrollOuter.appendChild(this.scrollbar)

    if (this._config.paddingSize) {
      const paddingDirection = this._config.direction === 'vertical' ? 'paddingRight' : 'paddingBottom'
      this.scrollInner.style[paddingDirection] = `${this._config.paddingSize}px`
    }

    this.scrollValue = Math.floor(this.rect[axis] * scrollPercent / 100)
    this.maxScrollValue = Math.floor(this.rect[axis] - this.scrollValue)
    scrollbarInner.style[axis] = (this.scrollValue - this._config.scrollSpacing) + 'px'

    if (typeof this._config.show === 'function') {
      this._config.show.call(this)
    }
  }

  addEventLitener() {
    const {
      SCROLL,
      WHEEL,
      MOUSEDOWN,
      MOUSEMOVE,
      MOUSEUP,
      MOUSEENTER,
      MOUSELEAVE,
      RESIZE
    } = this.constructor.Event

    const { scrollInner, scrollbar } = this

    EventHandler.on(scrollInner, SCROLL, e => this.handleScroll(e))
    EventHandler.on(scrollInner, WHEEL, e => this.handleWheel(e))
    EventHandler.on(scrollbar, MOUSEDOWN, e => this.handleMousedown(e))
    EventHandler.on(scrollbar, MOUSEMOVE, e => this.handleMousemove(e))
    EventHandler.on(scrollbar, MOUSEUP, () => this.handleMouseup())
    EventHandler.on(scrollInner, MOUSEENTER, e => {
      e.stopPropagation()
      this.mouseEntered = true
    })
    EventHandler.on(scrollInner, MOUSELEAVE, e => {
      e.stopPropagation()
      this.draggle = false
    })
    EventHandler.on(window, RESIZE, () => {
      if (!this._element.parentNode.offsetHeight) {
        return
      }

      // debouncing 처리
      if (this.resize) {
        clearTimeout(this.resize)
      }

      this.resize = setTimeout(() => {
        this.init()
        EventHandler.trigger(scrollInner, SCROLL)
      }, 50)
    })
  }

  update() {
    setTimeout(() => {
      this.init()
    }, 100)
  }

  handleMousedown(e) {
    const { acceleration } = this._config
    this.draggle = true
    if (this._config.direction === 'vertical') {
      this.lastPage.y = ((e.pageY - this.rect.top) * acceleration) - this.scrollInner.scrollTop
    } else {
      this.lastPage.x = ((e.pageX - this.rect.left) * acceleration) - this.scrollInner.scrollLeft
    }
  }

  handleMousemove(e) {
    if (!this.draggle) {
      return
    }

    e.preventDefault()
    e.stopPropagation()

    let delta = 0
    const { scrollDirection } = this
    const { acceleration } = this._config

    if (this._config.direction === 'vertical') {
      delta = ((e.pageY - this.rect.top) * acceleration) - this.lastPage.y
    } else {
      delta = ((e.pageX - this.rect.left) * acceleration) - this.lastPage.x
    }

    this.scrollInner[scrollDirection] = delta
  }

  handleMouseup() {
    this.draggle = false
  }

  handleScroll(e) {
    const distance = e.target[this.scrollDirection]

    // throttling 처리
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.scrollMove(distance)
        this.ticking = false
      })

      this.ticking = true
    }
  }

  handleWheel(e) {
    const { scrollDirection } = this
    if (scrollDirection === 'scrollTop') {
      return
    }

    const delta = e.wheelDelta * 0.1
    const distance = this.scrollInner[scrollDirection] - (delta * this._config.acceleration)

    if (e.wheelDelta > 0 && distance > 0) {
      e.preventDefault()
    }

    if (e.wheelDelta < 0 && distance < (this.scrollInner.scrollWidth - this.scrollOuter.scrollWidth)) {
      e.preventDefault()
    }

    this.scrollInner[scrollDirection] = distance
  }

  scrollMove(distance) {
    const { axis, scrollAxis } = this
    const max = this.scrollInner[scrollAxis] - this.rect[axis]
    const percent = distance * 100 / max
    const value = Math.floor(this.maxScrollValue * percent / 100)

    const translate = axis === 'height' ? 'translateY' : 'translateX'
    this.scrollbarInner.style.transform = `${translate}(${value}px)`
  }

  getDistance(delta) {
    return this._element[this.scrollDirection] + (delta * 2)
  }

  dispose() {
    this._element.removeAttribute('style')
    const childEls = this.scrollInner.children
    const originalEls = makeArray(childEls).map(el => el)
    this._element.removeChild(this.scrollInner)
    this._element.removeChild(this.scrollbar)
    makeArray(originalEls).forEach(el => {
      this._element.appendChild(el)
    })

    Data.removeData(this._element, this.constructor.DATA_KEY)
    EventHandler.off(this.scrollInner, this.constructor.EVENT_KEY)
    EventHandler.off(this.scrollOuter, this.constructor.EVENT_KEY)
    EventHandler.off(window, this.constructor.EVENT_KEY)
  }

  static get NAME() {
    return 'scrollbar'
  }

  static get DATA_KEY() {
    return 'fb.scrollbar'
  }

  static get EVENT_KEY() {
    return `.${this.DATA_KEY}`
  }

  static get Event() {
    return {
      SCROLL: `scroll${this.EVENT_KEY}`,
      WHEEL: `mousewheel${this.EVENT_KEY}`,
      MOUSEDOWN: `mousedown${this.EVENT_KEY}`,
      MOUSEMOVE: `mousemove${this.EVENT_KEY}`,
      MOUSEUP: `mouseup${this.EVENT_KEY}`,
      MOUSEENTER: `mouseenter${this.EVENT_KEY}`,
      MOUSELEAVE: `mouseleave${this.EVENT_KEY}`,
      RESIZE: `resize${this.EVENT_KEY}`
    }
  }

  static get Default() {
    return {
      outer: false,
      parentSelector: null,
      direction: 'vertical', // vertical, horzontal
      show: null,
      fixedEl: '.c-table__shadow',
      acceleration: 3,
      paddingSize: 0,
      scrollOffset: 0,
      scrollSpacing: 0
    }
  }

  static get DefaultType() {
    return {
      outer: 'boolean',
      parentSelector: 'string|null',
      direction: 'string',
      show: 'function|null',
      fixedEl: 'string',
      acceleration: 'number',
      paddingSize: 'number',
      scrollOffset: 'number',
      scrollSpacing: 'number'
    }
  }

  // Private
  _getConfig(config) {
    const {
      NAME,
      DefaultType
    } = this.constructor

    config = {
      ...this.constructor.Default,
      ...Manipulator.getDataAttributes(this._element),
      ...config
    }

    typeCheckConfig(NAME, config, DefaultType)
    return config
  }

  // static
  static getInstance(element) {
    return Data.getData(element, this.DATA_KEY)
  }

  static interface(element, config) {
    let data = this.getInstance(element)

    if (data) {
      data.init()
    } else {
      data = new Scrollbar(element, config)
    }
  }
}

export default Scrollbar
