import { makeArray, typeCheckConfig } from './util/index'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import SelectorEngine from './dom/selector-engine'
import Manipulator from './dom/manipulator'

const Default = {
  parentClass: null
}

class InputDots {
  constructor(element, config) {
    this._element = element
    this._config = this._getConfig(config)
    this.maxLength = parseInt(this._element.getAttribute('maxlength'), 10)

    if (this._config.parentClass) {
      this.parent = SelectorEngine.parents(this._element, this._config.parentClass)[0]
    } else {
      this.parent = this._element
    }

    this.dotEls = SelectorEngine.find(this._config.dotClass, this.parent)

    Data.setData(this._element, 'fb.input.dots', this)

    this.addEventHandler()
  }

  addEventHandler() {
    EventHandler.on(this._element, 'focus', () => {
      this.parent.classList.add('has-focus')
    })
    EventHandler.on(this._element, 'blur', () => {
      this.parent.classList.remove('has-focus')
    })
  }

  keyUp() {
    const { length } = this._element.value

    if (length > this.maxLength) {
      return
    }

    for (let i = 0; i < this.maxLength; i += 1) {
      if (i < length) {
        this.dotEls[i].classList.add('is-active')
      } else {
        this.dotEls[i].classList.remove('is-active')
      }
    }
  }

  _getConfig(config) {
    const dataAttributes = Manipulator.getDataAttributes(this._element)

    config = {
      ...Default,
      ...dataAttributes,
      ...typeof config === 'object' && config ? config : {}
    }

    if (typeof config.delay === 'number') {
      config.delay = {
        show: config.delay,
        hide: config.delay
      }
    }

    typeCheckConfig(
      'fb.input.dots',
      config,
      {
        dotClass: 'string'
      }
    )

    return config
  }

  // static
  static getInstance(element) {
    return Data.getData(element, 'fb.input.dots')
  }
}

makeArray(document.querySelectorAll('.certifi-security__input input')).forEach(el => {
  // eslint-disable-next-line no-unused-vars
  const els = new InputDots(el, {
    dotClass: '.certifi-security__dot',
    parentClass: '.certifi-security__input'
  })
})

makeArray(document.querySelectorAll('.login__password input')).forEach(el => {
  // eslint-disable-next-line no-unused-vars
  const els = new InputDots(el, {
    dotClass: '.login__dot',
    parentClass: '.login__password'
  })
})

EventHandler.on(document, 'keyup.fb.input.dots', '.certifi-security__input, .login__password', e => {
  // 이벤트 키가 숫자가 아닐 경우 리턴 문 추가해야함
  let data = InputDots.getInstance(e.target)

  if (!data) {
    data = InputDots(e.target)
  }

  data.keyUp()
})

EventHandler.on(document, 'keydown.fb.input.dots', '.certifi-security__input, .login__password', e => {
  const { length } = e.target.value
  const maxLength = parseInt(e.target.getAttribute('maxlength'), 10) - 1
  if (length > maxLength && !/Backspace|Tab/.test(e.key)) {
    e.preventDefault()
  }
})

export default InputDots
