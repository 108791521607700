// target 전달 방식 전체 수정해야함 2020.07.03
// import { getjQuery } from './util/index.js'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import SelectorEngine from './dom/selector-engine'
import { makeArray, typeCheckConfig } from './util/index'
import Manipulator from './dom/manipulator'

const NAME = 'toggle'
const VERSION = '1.0.0'
const DATA_KEY = 'fb.toggle'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Default = {
  // style: '(undefined|string)'
}

const DefaultType = {
  // style: 'string'
}

const Selector = {
  TOGGLE: '[data-toggle~="toggle"]'
}

const Event = {
  CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`,
  CHANGE_DATA_API: `change${EVENT_KEY}${DATA_API_KEY}`
}

class Toggle {
  constructor(element, config) {
    this._element = element
    this._config = this._getConfig(config)
    this.tagName = this._element.tagName.toLowerCase()
    this.targetEls = makeArray(SelectorEngine.find(this._config.target))

    if (this.tagName === 'input') {
      this._config.type = this._element.getAttribute('type')
    }

    if (this._config.type === 'radio') {
      const name = this._element.getAttribute('name')
      this.radioEls = document.querySelectorAll(`input[name="${name}"]`)
    }

    if (this.tagName === 'select') {
      this.options = SelectorEngine.find('option', this._element)
    }

    Data.setData(element, DATA_KEY, this)
  }

  // Getters

  static get VERSION() {
    return VERSION
  }

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  toggle() {
    // 타겟이 없을 경우 토글 함수 실행하지 않게 처리 필요
    if (!this.targetEls.length) {
      return
    }

    if (this.targetEls[0].style.display === 'none' || this.targetEls[0].disabled) {
      this.show()
    } else {
      this.hide()
    }
  }

  show(els = this.targetEls) {
    els.forEach(el => {
      if (this._config.style) {
        el.removeAttribute('disabled')
        this.parentStyle(el, 'show', 'disabled')
      } else if (el.getAttribute('type', 'checkbox')) {
        el.checked = true
      } else {
        el.style.display = ''
        this.parentStyle(el, 'show')
      }
    })
  }

  hide(els = this.targetEls) {
    els.forEach(el => {
      if (this._config.style) {
        el.setAttribute('disabled', '')
        this.parentStyle(el, 'hie', 'disabled')
      } else if (el.getAttribute('type', 'checkbox')) {
        el.checked = false
      } else {
        el.style.display = 'none'
        this.parentStyle(el, 'hide')
      }
    })
  }

  parentStyle(el, state, style) {
    const formField = SelectorEngine.parents(el, '.c-form__field')[0]

    if (!formField) {
      return
    }

    if (state === 'show' && style) {
      formField.classList.remove('is-disabled')
    }

    if (state === 'show' && !style) {
      formField.style.display = ''
    }

    if (state === 'hie' && style) {
      formField.classList.add('is-disabled')
    }

    if (state === 'hie' && !style) {
      formField.style.display = 'none'
    }
  }

  radioToggle() {
    makeArray(this.radioEls).forEach(el => {
      const target = el.getAttribute('data-target')
      const targetEl = makeArray(SelectorEngine.find(target))

      if (!target) {
        return
      }

      if (el.checked) {
        this.show(targetEl)
      } else {
        this.hide(targetEl)
      }
    })
  }

  selectToggle() {
    const index = this._element.selectedIndex
    makeArray(this.options).forEach((el, i) => {
      const target = el.getAttribute('data-target')
      const targetEl = makeArray(SelectorEngine.find(target))

      if (!target) {
        return
      }

      if (index === i) {
        this.show(targetEl)
      } else {
        this.hide(targetEl)
      }
    })
  }

  dispose() {
    Data.removeData(this._element, DATA_KEY)
    this._element = null
  }

  // private
  _getConfig(config) {
    config = {
      ...Manipulator.getDataAttributes(this._element),
      ...config
    }

    typeCheckConfig(
      NAME,
      config,
      this.constructor.DefaultType
    )

    return config
  }

  // Static
  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }
}

EventHandler.on(document, Event.CLICK_DATA_API, Selector.TOGGLE, e => {
  // e.stopPropagation()
  // e.preventDefault()

  const target = e.delegateTarget

  const tagName = target.tagName.toLowerCase()
  const type = tagName === 'input' && target.getAttribute('type')

  let data = Toggle.getInstance(target)

  if (!data) {
    data = new Toggle(target)
  }

  if (type === 'radio') {
    data.radioToggle()
  } else if (tagName === 'select') {
    data.selectToggle()
  } else {
    data.toggle()
  }
})

EventHandler.on(document, Event.CHANGE_DATA_API, Selector.TOGGLE, e => {
  const { target } = e

  let data = Toggle.getInstance(target)

  if (!data) {
    data = new Toggle(target)
  }

  data.selectToggle()
})

export default Toggle
