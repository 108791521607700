import {
  makeArray,
  typeCheckConfig
} from './util/index.js'
import Data from './/dom/data.js'
import EventHandler from './dom/event-handler.js'
import Manipulator from './dom/manipulator.js'
import SelectorEngine from './dom/selector-engine.js'

const NAME = 'tab'
const VERSION = '0.0.1'
const DATA_KEY = 'fb.tab'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const DefaultType = {
  selector: 'string'
}

const Default = {
  selector: '[data-toggle="tab"]',
  isIndex: 1
}

const ClassName = {
  SHOW: 'is-active'
}

const Event = {
  SHOW: `show${EVENT_KEY}`,
  SHOWN: `shown${EVENT_KEY}`,
  CLICKMENU: `click${EVENT_KEY}.menu`,
  CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`
}

class Tab {
  constructor(element, config) {
    this._element = element
    this._config = config

    this._config = this._getConfig(config)
    Data.setData(element, this.constructor.DATA_KEY, this)
    this._id = this._element.key.id
    this._tabList = SelectorEngine.findOne('[role="tablist"]', this._element)
    this._tabs = makeArray(SelectorEngine.children(this._tabList, '[role="tablist"] button'))
    this._panels = makeArray(SelectorEngine.children(this._element, '[role="tabpanel"]'))
    this._menu = SelectorEngine.findOne('.tab-menu', this._element)

    this._tabs.forEach((el, i) => {
      el.setAttribute('id', `tab-list${this._id}-${++i}`)
      el.setAttribute('aria-selected', 'false')
      el.setAttribute('aria-controls', `tab-panel${this._id}-${i}`)
      if (el.classList.contains('is-active')) {
        this._config.isIndex = i
      }

      EventHandler.on(el, Event.CLICK_DATA_API, e => {
        this.show(i)
      })
    })

    this._panels.forEach((el, i) => {
      el.setAttribute('id', `tab-panel${this._id}-${++i}`)
      el.setAttribute('aria-labelledby', `tab-list${this._id}-${i}`)
      // 탭 컨텐츠 안에 스크롤 있다면 탭인덱스 생성으로 수정해야함 2020.07.03
      // el.setAttribute('tabindex', '0')
    })

    if (this._menu) {
      const button = SelectorEngine.findOne('.tab-menu__button', this._menu)
      const buttonText = SelectorEngine.findOne('.sr-only', button)
      const title = SelectorEngine.findOne('.tab-menu__title', this._menu)
      const anchors = SelectorEngine.find('.tab-menu__anchor', this._menu)
      const tabWidth = this._tabList.offsetWidth - 60

      button.setAttribute('id', `tab-menu${this._id}`)
      makeArray(anchors).forEach((anchor, i) => {
        anchor.setAttribute('href', `#tab-panel${this._id}-${++i}`)
        EventHandler.on(anchor, 'click', e => {
          e.preventDefault()
          const left = this._tabs[i - 1].offsetLeft + this._tabs[i - 1].offsetWidth - tabWidth
          const text = e.target.textContent
          title.textContent = text
          this.show(i)

          button.focus()
          if (left > 0) {
            // this._tabList.scrollLeft = left
            this.scrollTo(left, 300)
          } else {
            // this._tabList.scrollLeft = 0
            this.scrollTo(0, 300)
          }
        })
      })

      EventHandler.on(this._menu, Event.CLICKMENU, () => {
        const text = buttonText.textContent
        if (this._menu.classList.contains('is-active')) {
          this._menu.classList.remove('is-active')
          buttonText.textContent = text.replace('닫기', '열기')
        } else {
          this._menu.classList.add('is-active')
          buttonText.textContent = text.replace('열기', '닫기')
        }
      })
    }

    this.show(this._config.isIndex)
  }

  // Getters
  static get VERSION() {
    return VERSION
  }

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  show(index) {
    const el = this._tabs[index - 1]

    const relatedTarget = {
      relatedTarget: el
    }

    const showEvent = EventHandler.trigger(this._element, Event.SHOW, relatedTarget)

    if (showEvent.defaultPrevented) {
      return
    }

    el.setAttribute('aria-selected', 'true')
    el.classList.add(ClassName.SHOW)
    this._panels[index - 1].classList.add(ClassName.SHOW)
    EventHandler.trigger(this._element, Event.SHOWN, relatedTarget)
    this.hide(index)
  }

  hide(index) {
    this._tabs.forEach((el, n) => {
      if (n + 1 === index) {
        return
      }

      el.setAttribute('aria-selected', 'false')
      el.classList.remove(ClassName.SHOW)
      this._panels[n].classList.remove(ClassName.SHOW)
    })
  }

  scrollTo(to, du) {
    const start = this._tabList.scrollLeft
    const change = to - start
    let currentTime = 0
    const increment = 20
    const duration = du

    const animateScroll = () => {
      currentTime += increment
      const val = this.easeInOutQuad(currentTime, start, change, duration)
      this._tabList.scrollLeft = val
      if (currentTime < duration) {
        setTimeout(animateScroll, increment)
      }
    }

    animateScroll()
  }

  easeInOutQuad(t, b, c, d) {
    let x = t / (d / 2)
    if (x < 1) {
      // eslint-disable-next-line no-mixed-operators
      return c / 2 * x * x + b
    }

    x--
    // eslint-disable-next-line no-mixed-operators
    return -c / 2 * (x * (x - 2) - 1) + b
  }

  // Private
  _getConfig(config) {
    config = {
      ...this.constructor.Default,
      ...Manipulator.getDataAttributes(this.element),
      ...config
    }

    typeCheckConfig(
      NAME,
      config,
      this.constructor.DefaultType
    )

    return config
  }

  // Static
  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }
}

// const tabList = [].slice.call(document.querySelectorAll('.c-tab, [data-toggle="tab"]'))

// tabList.forEach(el => {
//   return new Tab(el)
// })

export default Tab
