import { makeArray, isVisible } from './util/index.js'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import SelectorEngine from './dom/selector-engine'

// const VERSION = '0.0.1'
const DATA_KEY = 'fb.form'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const ClassName = {
  // FORM: 'c-form__field',
  FOCUS: 'has-focus',
  VALUE: 'has-value',
  ERROR: 'has-error'
  // DISABLED: 'is-disabled',
}

// const Selector = {
// VALUE: '.has-value',
// FORM: '.c-form__field'
// INPUT: '.c-form__field'
// INPUT: '.o-input:not([type="radio"]):not([type="checkbox"]), .attachment__input, .attachment__delete, .address__button'
// }

const Event = {
  // CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`,
  FOCUS_DATA_API: `focus${EVENT_KEY}${DATA_API_KEY}`,
  BLUR_DATA_API: `blur${EVENT_KEY}${DATA_API_KEY}`
}

class Form {
  constructor(element) {
    this._element = element

    this._input = SelectorEngine.findOne('input, select, textarea, button', this._element)
    this._label = SelectorEngine.findOne('.c-form__label', this._element)
    Data.setData(element, DATA_KEY, this)

    this.isValue = false
    if (this._element.classList.contains('has-value')) {
      this.isValue = true
    }

    this.hasDisabled()
    this.hasValue()
    this.selectLabelInit()
    this.addListener()
  }

  hasDisabled() {
    const input = SelectorEngine.find('input, textarea, select', this._element)

    makeArray(input).forEach(el => {
      if (!isVisible(el)) {
        return
      }

      if (el.disabled && !this._element.classList.contains('is-disabled')) {
        this._element.classList.add('is-disabled')
      }

      if (!el.disabled && this._element.classList.contains('is-disabled')) {
        el.disabled = true
      }
    })
  }

  hasValue() {
    if (this.isValue) {
      return
    }

    if (SelectorEngine.findOne('select', this._element) || /이메일|상담희망시간|상승률/.test(this._label.textContent)) {
      this._element.classList.add('has-value')
      return
    }

    const input = SelectorEngine.findOne('input, textarea', this._element)
    if (!isVisible(input)) {
      return
    }

    if (input.value === '') {
      this._element.classList.remove('has-value')
    } else {
      this._element.classList.add('has-value')
    }
  }

  selectLabelInit() {
    // todos: 7/6  - 레이블 값만 있는 경우, 아이디 값만 있는 경우도 처리 필요함
    const label = SelectorEngine.findOne('label', this._element)

    if (!label || label.getAttribute('for')) {
      return
    }

    const formEl = SelectorEngine.findOne('select, input, textarea', this._element)
    const tagName = formEl.tagName.toLowerCase()
    label.setAttribute('for', `${this._element.key.key}-${tagName}-${this._element.key.id}`)
    formEl.setAttribute('id', `${this._element.key.key}-${tagName}-${this._element.key.id}`)
  }

  error(msg) {
    const formEl = SelectorEngine.findOne('select, input, textarea', this._element)
    // const id = this._element.getAttribute('id')

    this._element.classList.add(ClassName.ERROR)
    this._element.classList.remove(ClassName.VALUE)
    this._element.classList.remove(ClassName.FOCUS)

    formEl.setAttribute('aria-invalid', true)
    formEl.setAttribute('aria-describedby', `error-message-${this._element.key.id}`)

    const errorEl = document.createElement('p')
    errorEl.textContent = msg || 'error'
    errorEl.setAttribute('class', 'message-error')
    errorEl.setAttribute('id', `error-message-${this._element.key.id}`)

    this._element.appendChild(errorEl)

    // const next = this._element.nextElementSibling

    // if (next && next.matches('.c-form__field')) {
    //   this._element.appendChild(errorEl)
    // } else {
    //   this._element.parentNode.appendChild(errorEl)
    // }
  }

  dispose() {
    Data.removeData(this._element, DATA_KEY)
    this._element = null
  }

  emailSelect(e) {
    const { target } = e
    const index = target.selectedIndex
    const formRow = this._element.querySelectorAll('.c-form__row')
    const parent = target.parentNode
    const siblings = makeArray(formRow).filter(el => el !== parent)
    const options = SelectorEngine.find('option', target)

    if (index < 1) {
      siblings[0].style.display = ''
      siblings[1].style.display = 'none'
    } else {
      siblings[0].style.display = 'none'
      siblings[1].style.display = ''
      const domain = options[index].getAttribute('data-domain')
      const textEl = SelectorEngine.findOne('.c-form__text', this._element)
      textEl.textContent = domain
    }
  }

  // Static
  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }

  addListener() {
    EventHandler.on(this._element, Event.FOCUS_DATA_API, 'input, textarea, select, button', e => {
      const { target } = e
      const label = SelectorEngine.findOne('.c-form__label', this._element)

      if (/직업/.test(label.textContent)) {
        return
      }

      this._element.classList.add(ClassName.FOCUS)

      const title = target.getAttribute('title')

      // 이메일
      if (title === '도메인' && target.tagName === 'SELECT') {
        this.emailSelect(e)

        EventHandler.off(target, 'change')
        EventHandler.on(target, 'change', e => {
          this.emailSelect(e)
        })
      }

      if (label.textContent === '상담희망시간') {
        const lastRow = SelectorEngine.findOne('.c-form__row:last-child', this._element)
        lastRow.style.display = 'block'
      }
    })

    EventHandler.on(this._element, Event.BLUR_DATA_API, 'input, textarea, select, button', e => {
      const formEls = SelectorEngine.find('select, input, textarea, button', this._element)
      const children = makeArray(formEls).filter(el => e.relatedTarget === el)

      if (children.length > 0) {
        return
      }

      this.hasValue(e)

      this._element.classList.remove(ClassName.FOCUS)
    })
  }

  changeDisabled(state = true) {
    const items = SelectorEngine.find('input, textarea, select', this._element)
    makeArray(items).forEach(item => {
      item.disabled = state
      if (state) {
        this._element.classList.add('is-disabled')
      } else {
        this._element.classList.remove('is-disabled')
      }
    })
  }
}

export default Form
