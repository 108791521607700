/**
 * --------------------------------------------------------------------------
 * alert.js
 * --------------------------------------------------------------------------
 */
const NAME = 'alert'
const DATA_KEY = 'fb.alert'

const DefaultType = {
  title: 'string',
  message: 'string',
  button: 'number',
  toast: 'number'
}

const Default = {
  title: '',
  message: '',
  button: 0,
  toast: 0
}

import { typeCheckConfig } from './util/index.js'
import Data from './dom/data.js'
import Modal from './modal.js'
import SelectorEngine from './dom/selector-engine.js'
import EventHandler from './dom/event-handler.js'
// import EventHandler from './dom/event-handler.js'
// import SelectorEngine from './dom/selector-engine.js'

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */

const template = `
  <section class="c-modal__dialog c-modal--sm" role="document">
    <div class="c-modal__content">
      <h1 class="c-modal__title c-modal__title--center" id="alertdialogTitle"></h1>
      <div class="c-modal__body">
        <div class="content ta-center" id="alertdialogDescription"></div>
      </div>
      <div class="c-modal__foot">
        <button type="button" class="c-modal__button c-modal__button--cancel" data-dismiss="modal">취소</button>
        <button type="button" class="c-modal__button c-modal__button--confirm" data-dismiss="modal">확인</button>
      </div>
    </div>
  </section>
`
/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

class Alert {
  constructor(config) {
    this._element = document.createElement('div')
    this._config = this._getConfig(config)
    this._element.setAttribute('class', 'c-modal fade')
    this._element.setAttribute('id', 'alertdialog')
    this._element.setAttribute('role', 'alertdialog')
    this._element.setAttribute('aria-labelledby', 'alertdialogTitle')
    this._element.setAttribute('describedby', 'alertdialogDescription')
    this._element.setAttribute('aria-hidden', 'false')
    this._element.setAttribute('tabindex', '-1')
    this._element.style.zIndex = 1060

    const body = document.querySelector('body')
    this._element.innerHTML = template
    this._title = this._element.querySelector('.c-modal__title')
    this._buttonCancel = this._element.querySelector('.c-modal__button--cancel')
    this._buttonConfirm = this._element.querySelector('.c-modal__button--confirm')

    if (this._config.title) {
      this._title.textContent = this._config.title
    } else {
      this._title.parentNode.removeChild(this._title)
    }

    this._element.querySelector('.content').innerHTML = this._config.message

    if (!this._config.button) {
      this._buttonCancel.parentNode.removeChild(this._buttonCancel)
    }

    if (this._config.button === 2) {
      this._buttonCancel.textContent = '아니요'
      this._buttonConfirm.textContent = '예'
    }

    if (this._config.toast) {
      this._element.querySelector('.c-modal__dialog').classList.add('c-modal__dialog--toast')
      this._element.querySelector('.c-modal__foot').parentNode.removeChild(this._element.querySelector('.c-modal__foot'))
      this._element.addEventListener('show.fb.modal', () => {
        setTimeout(() => {
          this._modal.hide()
          this._config.relatedTarget.focus()
        }, 3000)
      })
    }

    body.appendChild(this._element)

    this._buttonConfirm.addEventListener('click', () => {
      body.removeChild(this._element)
    })

    setTimeout(() => {
      this._modal = new Modal(this._element, {
        backdrop: 'static'
      })
      this._modal.show()
      // this._element.addEventListener('show.fb.modal', () => {
      //   alert('a')
      // })
      this._modal._backdrop.style.zIndex = 1059
    }, 100)
  }

  // getters
  static get Default() {
    return Default
  }

  static get DATA_KEY() {
    return DATA_KEY
  }

  static get DefaultType() {
    return DefaultType
  }

  _getConfig(config) {
    config = {
      ...this.constructor.Default,
      ...typeof config === 'object' && config ? config : {}
    }

    typeCheckConfig(
      NAME,
      config,
      this.constructor.DefaultType
    )

    return config
  }
}

export default Alert
