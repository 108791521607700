
import { makeArray, getUID } from './src/util/index'
import SelectorEngine from './src/dom/selector-engine'
import EventHandler from './src/dom/event-handler'
import Alert from './src/alert.js'
import Collapse from './src/collapse'
import Modal from './src/modal'
import Tab from './src/tab'
import Tooltip from './src/tooltip'
import Form from './src/form'
import CheckAll from './src/check-all'
import CheckDisabled from './src/check-disabled'
import InputDots from './src/input-dots'
import Toggle from './src/toggle'
import Loading from './src/loading'
import LabelFor from './src/label-for'
import Dropdown from './src/dropdown'
import Scrollbar from './src/scrollbar'
import ScrollMove from './src/scroll-move'

const { body } = document
const header = body.querySelector('.l-head')
const layoutWrapper = body.querySelector('.l-wrap')
const breadcrumb = body.querySelector('.breadcrumb')
let isHeaderActive = false

const mainPage = () => {
  const topBanner = body.querySelector('.top-banner')
  const floatingBanner = body.querySelector('.floating-banner')
  const mainBg = body.querySelector('.main-head__bg')
  const mainBgChild = makeArray(document.querySelectorAll('.main-head__shape'))
  const mainLogin = body.querySelector('.main-login')
  const mainMenu = body.querySelector('.main-menu')
  const mainMenuPanel = makeArray(body.querySelectorAll('.main-menu__panel'))
  const mainLoginListButton = body.querySelectorAll('.main-login__list button')
  let translateY = 0


  window.history.scrollRestoration = 'manual'

  // 탑배너 및 플로팅 배너 위치 이동 및 메인 클래스 생성
  body.classList.add('main')
  // eslint-disable-next-line unicorn/prefer-modern-dom-apis
  body.insertBefore(topBanner, layoutWrapper)
  // eslint-disable-next-line unicorn/prefer-modern-dom-apis
  body.insertBefore(floatingBanner, layoutWrapper)

  const calcValue = (yOffset, startValue, endValue, startPoint = 0, endPoint = 1) => {
    const scrollHeight = document.body.offsetHeight - window.innerHeight
    const startHeight = startPoint * scrollHeight
    const endHeight = endPoint * scrollHeight
    const rangeHeight = endHeight - startHeight
    const scrollRatio = (yOffset - startHeight) / rangeHeight
    let value = 0

    if (yOffset >= startHeight && yOffset <= endHeight) {
      value = (scrollRatio * (endValue - startValue)) + startValue
    } else if (yOffset < startHeight) {
      value = startValue
    } else if (yOffset > endHeight) {
      value = endValue
    }

    return value
  }

  const mainScrollMove = () => {
    const yOffset = window.pageYOffset
    const headerStartPoint = (mainBg.offsetHeight - header.offsetHeight) / (document.body.offsetHeight - window.innerHeight)
    // 로그인
    const value1 = calcValue(yOffset, translateY, translateY - 200, 0, 1)
    const value2 = calcValue(yOffset, 1, 0, 0, 0.5)
    // 자주찾는 메뉴
    const value3 = calcValue(yOffset, translateY, translateY - 500, 0, 1)
    const value4 = calcValue(yOffset, 1, 0, 0, 0.5)
    // 헤더
    const value5 = calcValue(yOffset, 0, 1, headerStartPoint, 1)

    mainLogin.style.transform = `translate3d(0, ${value1}px, 0)`
    mainLogin.style.opacity = `${value2}`
    mainMenu.style.transform = `translate3d(0, ${value3}px, 0)`
    mainMenu.style.opacity = `${value4}`

    mainBgChild.forEach(el => {
      const r = Math.ceil(Math.random() * (0 - 300))
      const v = calcValue(yOffset, 0, r, 0, 0.3)
      el.style.transform = `translate3d(0, ${v}px, 0)`
    })

    if (value5) {
      header.classList.remove('l-head--main')
    } else {
      if (isHeaderActive) {
        return
      }

      header.classList.add('l-head--main')
    }
  }

  // 상단 배너 노출
  const topBannerActive = () => {
    if (!topBanner) {
      return
    }

    const closeButton = document.body.querySelector('.top-banner__close')
    window.scrollTo(0, 0)

    closeButton.addEventListener('click', () => {
      const complete = () => {
        topBanner.style.display = 'none'

        body.removeEventListener('transitionend', complete)
      }

      layoutWrapper.style.marginTop = ''
      window.scrollTo(0, 0)

      topBanner.style.zIndex = -1
      mainScrollMove()

      setTimeout(() => {
        body.classList.remove('slide-down')
        layoutWrapper.style.marginTop = ''
        header.style.transform = ''
        mainLogin.style.transform = ''
        mainBg.style.transform = ''
        mainMenu.style.transform = ''
        translateY = 0
      }, 0)

      body.addEventListener('tansitionend', complete)
    })

    topBanner.style.display = 'block'
    const height = topBanner.offsetHeight

    setTimeout(() => {
      body.classList.add('slide-down')
      layoutWrapper.style.marginTop = height + 'px'
      header.style.transform = `translate(0, ${height}px)`
      mainLogin.style.transform = `translate(0, ${height}px)`
      mainBg.style.transform = `translate(0, ${height}px)`
      mainMenu.style.transform = `translate(0, ${height}px)`
      translateY = height
      mainScrollMove()

      const zIndexChange = () => {
        topBanner.style.zIndex = 2
        body.removeEventListener('transitionend', zIndexChange)
      }

      body.addEventListener('transitionend', zIndexChange)
    }, 0)
  }

  function loginBgGradient() {
    const color = [
      ['#0076c0', '#00a19c'],
      ['#00598d', '#004e7a'],
      ['#0076c0', '#0076c0'],
      ['#ffe403', '#ffe403'],
      ['#d2d2d2', '#d2d2d2']
    ]
    const gradientSpped = 0.02
    let step = 0

    let prevColor1 = getRGB(color[0][0])
    let prevColor2 = getRGB(color[0][1])
    let rafId = false
    let index
    let lastIndex

    function getRGB(color) {
      const hex = color.replace('#', '')
      const hexValue = hex.match(/[a-f\d]{2}/gi)
      return [parseInt(hexValue[0], 16), parseInt(hexValue[1], 16), parseInt(hexValue[2], 16)]
    }

    const animation = () => {
      let istep = 1 - step
      const nextColor1 = getRGB(color[index][0])
      const nextColor2 = getRGB(color[index][1])

      if (step > 0 && index !== lastIndex) {
        cancelAnimationFrame(rafId)
        if (step > 0.5) {
          step = 1 - step
          istep = 1 - step
        }

        const temColor1 = getRGB(color[lastIndex][0])
        const temColor2 = getRGB(color[lastIndex][1])

        const r1 = Math.round((istep * temColor1[0]) + (step * nextColor1[0]))
        const g1 = Math.round((istep * temColor1[1]) + (step * nextColor1[1]))
        const b1 = Math.round((istep * temColor1[2]) + (step * nextColor1[2]))

        const r2 = Math.round((istep * temColor2[0]) + (step * nextColor2[0]))
        const g2 = Math.round((istep * temColor2[1]) + (step * nextColor2[1]))
        const b2 = Math.round((istep * temColor2[2]) + (step * nextColor2[2]))

        prevColor1 = [r1, g1, b1]
        prevColor2 = [r2, g2, b2]
      }

      const r1 = Math.round((istep * prevColor1[0]) + (step * nextColor1[0]))
      const g1 = Math.round((istep * prevColor1[1]) + (step * nextColor1[1]))
      const b1 = Math.round((istep * prevColor1[2]) + (step * nextColor1[2]))

      const r2 = Math.round((istep * prevColor2[0]) + (step * nextColor2[0]))
      const g2 = Math.round((istep * prevColor2[1]) + (step * nextColor2[1]))
      const b2 = Math.round((istep * prevColor2[2]) + (step * nextColor2[2]))


      const rgb1 = `rgb(${r1}, ${g1}, ${b1})`
      const rgb2 = `rgb(${r2}, ${g2}, ${b2})`

      document.querySelector('.main-head__bg').style.background = `linear-gradient(120deg, ${rgb1} 60%, ${rgb2} 100%)`

      if (step >= 1) {
        step = 0
        prevColor1 = nextColor1
        prevColor2 = nextColor2
      } else {
        step += gradientSpped
        lastIndex = index
        rafId = requestAnimationFrame(() => {
          animation(index)
        })
      }
    }

    const init = idx => {
      index = idx
      animation()
    }

    return init
  }

  const setBg = loginBgGradient()

  // 배경이미지 변경 및 메인 메뉴 변경
  const selectLogin = () => {
    makeArray(mainLoginListButton).forEach((el, n) => {
      el.addEventListener('click', () => {
        if (n === 0) {
          mainMenuPanel.forEach(panel => {
            panel.querySelectorAll('div')[0].style.display = 'block'
            panel.querySelectorAll('div')[1].style.display = 'none'
          })
          el.parentNode.classList.remove('right')
          setBg(0)
        } else {
          mainMenuPanel.forEach(panel => {
            panel.querySelectorAll('div')[0].style.display = 'none'
            panel.querySelectorAll('div')[1].style.display = 'block'
          })
          el.parentNode.classList.add('right')
          setBg(1)
        }
      })
    })
  }

  // 스와이퍼 실행
  const swiper = () => {
    const mySwiper = new Swiper('.swiper-container.main-menu-swiper', {
      slidesPerView: 'auto',
      spaceBetween: 0,
      centeredSlides: true,
      effect: 'fade',
      keyboard: {
        enabled: false
      },
      pagination: {
        el: '.main-menu-swiper__page',
        type: 'fraction'
      }
    })

    const swiperEl = document.querySelector('.main-menu-swiper .swiper-wrapper')
    const swiperPrevButton = document.querySelector('.main-menu-swiper .main-menu-swiper__button--prev')
    const swiperNextButton = document.querySelector('.main-menu-swiper .main-menu-swiper__button--next')
    swiperEl.addEventListener('keydown', e => {
      setTimeout(() => {
        swiperEl.parentNode.scrollLeft = 0
      }, 0)

      if (e.key === 'Tab') {
        if (e.shiftKey) {
          mySwiper.slidePrev()
        } else {
          mySwiper.slideNext()
        }
      }
    })

    swiperPrevButton.addEventListener('click', () => {
      mySwiper.slideTo(mySwiper.activeIndex - 1)
    })

    swiperNextButton.addEventListener('click', () => {
      mySwiper.slideTo(mySwiper.activeIndex + 1)
    })
  }

  const floatingBannerActive = () => {
    if (!floatingBanner) {
      return
    }

    const panelCloseBtn = floatingBanner.querySelector('.floating-banner__close')
    const complete = () => {
      floatingBanner.style.display = 'none'
      floatingBanner.removeEventListener('transitionend', complete)
    }

    floatingBanner.style.display = 'block'

    setTimeout(() => {
      floatingBanner.classList.add('is-active')
    }, 0)

    panelCloseBtn.addEventListener('click', () => {
      floatingBanner.classList.remove('is-active')
      floatingBanner.addEventListener('transitionend', complete)
    })
  }

  const mainLoginBgChange = () => {
    const mainLogin = document.querySelector('.main-login')
    const parents = makeArray(document.querySelectorAll('#loginBefore .login-list__item'))

    function togglClass(item) {
      parents.forEach(el => {
        if (el === item) {
          item.classList.add('is-active')
        } else {
          el.classList.remove('is-active')
        }
      })
    }

    function removeClass() {
      const className = mainBg.getAttribute('class').match(/(\sbg\d)/)
      if (className) {
        mainBg.classList.remove(className[0].trim())
      }
    }

    function chageBg(item) {
      if (item.classList.contains('login-list__item--type1')) {
        // setBg(2)
        mainBg.classList.add('bg1')
        header.classList.add('l-head--main')
      } else if (item.classList.contains('login-list__item--type2')) {
        // setBg(3)
        mainBg.classList.add('bg2')
        header.classList.remove('l-head--main')
      } else if (item.classList.contains('login-list__item--type3')) {
        // setBg(4)
        mainBg.classList.add('bg3')
        header.classList.remove('l-head--main')
      }
    }

    function show(e) {
      const { target } = e

      removeClass()
      chageBg(target)
      togglClass(target)
      mainLogin.classList.add('is-active')
    }

    function hide(e) {
      if (SelectorEngine.matches(e.relatedTarget, '.login-list__anchor')) {
        return
      }

      removeClass()
      togglClass()
      const mainMenuPanel = makeArray(document.querySelectorAll('.main-login__panel'))
      if (mainMenuPanel[0].classList.contains('is-active')) {
        setBg(0)
      } else {
        setBg(1)
      }

      header.classList.add('l-head--main')
      mainLogin.classList.remove('is-active')
    }

    // EventHandler.on(mainHead, 'mouseover', show)
    // EventHandler.on(mainHead, 'focusin', show)

    const items = [].slice.call(document.querySelectorAll('.login-list--before .login-list__item'))
    items.forEach(item => {
      item.addEventListener('mouseenter', show)
    })

    const loginList = [].slice.call(document.querySelectorAll('.login-list--before'))
    loginList.forEach(item => {
      item.addEventListener('mouseout', hide)
    })
  }

  return {
    selectLogin,
    swiper,
    mainLoginBgChange,
    topBannerActive,
    floatingBannerActive,
    mainScrollMove
  }
}

// public

const dimmedToggle = () => {
  const dimmed = document.createElement('div')
  dimmed.classList.add('o-dimmed')
  dimmed.classList.add('fade')
  dimmed.classList.add('is-active')
  dimmed.setAttribute('aria-hidden', true)

  return state => {
    if (state === true) {
      body.appendChild(dimmed)
      body.style.paddingRight = '17px'
      header.style.paddingRight = '17px'
      body.classList.add('has-modal')
      header.style.transition = 'none'
    } else {
      body.classList.remove('has-modal')
      body.removeChild(dimmed)
      body.style.paddingRight = 0
      header.style.paddingRight = 0
      header.style.transition = ''
    }
  }
}

// 글로벌 내비게이션
const gnbNav = () => {
  if (!header) {
    return
  }

  const gnbOneDepthSelector = '.gnb > ul > .gnb__item'
  const gnbOneDepth = makeArray(document.querySelectorAll(gnbOneDepthSelector))

  const show = e => {
    showMenu(e)
    isHeaderActive = true
    if (body.classList.contains('main')) {
      header.classList.remove('l-head--main')
    }
  }

  const hide = () => {
    hideMenu()
    isHeaderActive = false
    if (body.classList.contains('main') && window.pageYOffset < 300) {
      header.classList.add('l-head--main')
    }
  }

  const showMenu = e => {
    const { target } = e
    const item = SelectorEngine.parents(target, gnbOneDepthSelector)[0]

    if (item) {
      item.children[0].classList.add('is-active')
      const { innerHeight } = window
      const headHeight = document.querySelector('.l-head').offsetHeight
      const panel = item.querySelector('.gnb__panel')

      if (SelectorEngine.matches(target, '.gnb__anchor--1depth')) {
        if (innerHeight - headHeight < panel.offsetHeight) {
          panel.style.height = `${innerHeight - headHeight}px`
        } else {
          panel.style.height = ''
        }

        let data = Scrollbar.getInstance(item.querySelector('.gnb__inside'))
        setTimeout(() => {
          if (data) {
            data.scrollWrapper.scrollTo(0, 0)
          } else {
            data = new Scrollbar(item.querySelector('.gnb__inside'), {
              scrollOffset: 0,
              scrollSpacing: 40
            })
          }
        }, 100)
      }

      hideMenu(item)
    } else {
      hideMenu()
    }
  }

  const hideMenu = item => {
    gnbOneDepth.forEach(el => {
      if (item !== el) {
        el.children[0].classList.remove('is-active')
      }
    })
  }

  header.addEventListener('mouseover', show)
  header.addEventListener('mouseleave', hide)
  header.addEventListener('focusin', show)

  const panelAnchors = makeArray(document.querySelectorAll('.gnb__panel .gnb__anchor'))
  panelAnchors.forEach(el => {
    EventHandler.on(el, 'click', hide)
  })
}

const moveFocus = (e, element, callBack) => {
  if (e.which !== 9) {
    return
  }

  const { target } = e
  const focusEl = makeArray(element.querySelectorAll('button, a'))
  const visibileEl = focusEl.filter(el => el.offsetHeight)
  const firstEl = visibileEl[0]
  const lastEl = visibileEl[visibileEl.length - 1]

  if (e.shiftKey && target === firstEl) {
    if (callBack) {
      callBack()
    } else {
      e.preventDefault()
      lastEl.focus()
    }
  }

  if (!e.shiftKey && target === lastEl) {
    if (callBack) {
      callBack()
    } else {
      e.preventDefault()
      firstEl.focus()
    }
  }
}

// 패밀리사이트
const familySite = document.querySelector('.foot-family-site')
if (familySite) {
  const button = familySite.querySelector('button')
  const srOnly = button.querySelector('.sr-only')

  const callBack = () => {
    EventHandler.trigger(button, 'click')
  }

  const bodyClick = e => {
    if (!SelectorEngine.parents(e.target, '.foot-family-site')[0]) {
      // callBack();

      familySite.classList.remove('is-active');
      srOnly.textContent = '열기';
    }
  }

  button.addEventListener('click', () => {
    if (familySite.classList.contains('is-active')) {
      familySite.classList.remove('is-active')
      srOnly.textContent = '열기'
    } else {
      familySite.classList.add('is-active')
      srOnly.textContent = '닫기'
      familySite.addEventListener('keydown', e => {
        moveFocus(e, familySite, callBack)
      })
      document.addEventListener('click', bodyClick)
    }
  })
}

// 알람메세지
const alarmToggle = () => {
  if (!document.querySelector('.l-side--alarm')) {
    return
  }

  const trigger = document.querySelector('#linkAlarm')
  const allPanel = document.querySelector('.l-side--alarm')
  const panelCloseBtn = allPanel.querySelector('.l-side__close')
  const dimmed = dimmedToggle()
  const scrollWrap = allPanel.querySelector('.l-side__panel')
  const triggerWrap = document.querySelector('#login-anchor-1')

  const onToggle = () => {
    if (allPanel.classList.contains('is-active')) {
      allPanel.classList.remove('is-active')
      setTimeout(() => {
        const evt = document.createEvent('HTMLEvents')

        evt.initEvent('click', true, true)
        triggerWrap.dispatchEvent(evt)
        allPanel.style.display = 'none'
        EventHandler.off(allPanel, 'keydown.moveFocus')
        dimmed(false)
        trigger.focus()
      }, 100)
    } else {
      allPanel.style.display = 'block'
      EventHandler.on(allPanel, 'keydown.moveFocus', e => {
        moveFocus(e, allPanel)
      })
      dimmed(true)
      setTimeout(() => {
        allPanel.focus()
        allPanel.classList.add('is-active')
      }, 100)
      setTimeout(() => {
        Scrollbar.interface(scrollWrap, {
          scrollOffset: 5,
          scrollSpacing: 40
        })
      }, 300)
    }
  }

  trigger.addEventListener('click', onToggle)
  panelCloseBtn.addEventListener('click', onToggle)
}

// 전체 메뉴 열림
const allMenuToggle = () => {
  if (!document.querySelector('.l-side--all')) {
    return
  }

  const trigger = document.querySelector('.l-head__side')
  const allPanel = document.querySelector('.l-side--all')
  const panelCloseBtn = allPanel.querySelector('.l-side__close')
  const scrollWrap = allPanel.querySelector('.l-side__panel')
  const dimmed = dimmedToggle()

  const onToggle = e => {
    e.preventDefault()

    if (allPanel.classList.contains('is-active')) {
      allPanel.classList.remove('is-active')
      dimmed('false')
      setTimeout(() => {
        allPanel.style.display = 'none'
        EventHandler.off(allPanel, 'keydown.moveFocus')
        trigger.focus()
      }, 100)
    } else {
      allPanel.style.display = 'block'
      EventHandler.on(allPanel, 'keydown.moveFocus', e => {
        moveFocus(e, allPanel)
      })
      dimmed(true)
      setTimeout(() => {
        allPanel.focus()
        allPanel.classList.add('is-active')
      }, 100)
      setTimeout(() => {
        Scrollbar.interface(scrollWrap, {
          scrollOffset: 5,
          scrollSpacing: 40
        })
      }, 300)
    }
  }

  trigger.addEventListener('click', onToggle)
  panelCloseBtn.addEventListener('click', onToggle)
}

// 메뉴 검색
const menuSearchToggle = () => {
  if (!document.querySelector('.l-side--search')) {
    return
  }

  const trigger = document.querySelector('.l-head__search')
  const allPanel = document.querySelector('.l-side--search')
  const panelCloseBtn = allPanel.querySelector('.l-side__close')
  const searchButton = allPanel.querySelector('.menu-search__button')
  const result = allPanel.querySelector('.menu-search__result')
  const panel = allPanel.querySelector('.l-side__panel')
  const dimmed = dimmedToggle()
  let scrollBar = false

  const onToggle = e => {
    e.preventDefault()

    if (allPanel.classList.contains('is-active')) {
      allPanel.classList.remove('is-active')
      dimmed(false)
      setTimeout(() => {
        allPanel.style.display = 'none'
        EventHandler.off(allPanel, 'keydown.moveFocus')
        trigger.focus()
        if (result) {
          result.style.display = 'none'
          result.classList.remove('is-active')
        }

        panel.style.display = 'none'
        panel.classList.remove('is-active')
        if (scrollBar) {
          scrollBar.dispose()
        }
      }, 100)
    } else {
      allPanel.style.display = 'block'
      EventHandler.on(allPanel, 'keydown.moveFocus', e => {
        moveFocus(e, allPanel)
      })
      dimmed(true)
      setTimeout(() => {
        allPanel.focus()
        allPanel.classList.add('is-active')
      }, 100)
    }
  }

  const addResult = () => {
    if (result) {
      result.style.display = 'block'
      setTimeout(() => {
        result.classList.add('is-active')
      }, 0)
    }

    panel.style.display = 'block'
    setTimeout(() => {
      panel.classList.add('is-active')
      scrollBar = new Scrollbar(panel, {
        scrollOffset: 5,
        scrollSpacing: 40
      })
      if (!panel.querySelector('.scroll-wrapper')) {
        scrollBar = false
      }
    }, 0)
  }

  trigger.addEventListener('click', onToggle)
  panelCloseBtn.addEventListener('click', onToggle)
  searchButton.addEventListener('click', addResult)
}

// 메뉴 검색 폼
const menuSearchFormInput = () => {
  const el = document.querySelector('.menu-search__input')
  if (!el) {
    return
  }

  const input = el.querySelector('input')
  const button = el.querySelector('button')

  input.addEventListener('keyup', e => {
    if (e.target.value) {
      el.classList.add('is-active')
      button.style.display = 'block'
    } else {
      el.classList.remove('is-active')
      button.style.display = 'none'
    }
  })

  button.addEventListener('click', () => {
    el.classList.remove('is-active')
    input.value = ''
    input.focus()
    button.style.display = 'none'
  })
}

// 상단 로그인 후 알림/로그아웃 토글
const headerLoginToggle = () => {
  const trigger = document.querySelector('#login-anchor-1')
  const allPanel = document.querySelector('#login-panel-1')
  if (!allPanel) {
    return
  }

  const handleClick = e => {
    if (e.target.parentNode !== allPanel) {
      onToggle(e)
    }
  }

  const onToggle = e => {
    e.preventDefault()
    e.stopPropagation()

    if (allPanel.classList.contains('is-active')) {
      allPanel.classList.remove('is-active')
      body.removeEventListener('click', handleClick)
    } else {
      allPanel.classList.add('is-active')
      body.addEventListener('click', handleClick)
    }
  }

  trigger.removeEventListener('click', onToggle)
  trigger.addEventListener('click', onToggle)
  const anchor = allPanel.querySelectorAll('.head-login__anchor')
  anchor[0].addEventListener('keydown', e => {
    if (e.which === 9 && e.shiftKey) {
      e.preventDefault()
      trigger.focus()
      onToggle(e)
    }
  })
  anchor[1].addEventListener('keydown', e => {
    if (e.which === 9 && !e.shiftKey) {
      e.preventDefault()
      document.querySelector('.l-head__search').focus()
      onToggle(e)
    }
  })
}

const setLayoutInit = () => {
  // let oldMenuId

  return historyState => {
    if (/^\/html.html/.test(window.location.pathname)) {
      return
    }

    const nowMenuId = historyState ? historyState.menuId : ''
    let { offsetHeight } = document.body
    let tid = false

    // 메인 체크 COM010103000000|COM010103000001|COM010103000002
    if (/COM010103000000|COM010103000001|COM010103000002/.test(nowMenuId) !== true) {
      body.classList.remove('main')
      if (header) {
        header.classList.remove('l-head--main')
        breadcrumb.style.display = 'block'
      }
    }

    function getHeight() {
      if (!tid) {
        tid = setTimeout(() => {
          tid = null
          if (offsetHeight === document.body.offsetHeight) {
            if (/COM010103000000|COM010103000001|COM010103000002/.test(nowMenuId)) {
              // 메인일 경우 처리 사항
            } else {
              // window.history.scrollRestoration = 'manual'
            }

            completedInit()
            clearTimeout(tid)
          } else {
            offsetHeight = document.body.offsetHeight
            getHeight()
          }
        }, 300)
      }
    }

    getHeight()
    // oldMenuId = nowMenuId
  }
}

const skipNavigation = () => {
  const el = document.querySelector('#skipNavigation a')
  if (!el) {
    return
  }

  el.addEventListener('click', e => {
    e.preventDefault()
    document.querySelector('#content').focus()
  })
}

const setLayout = setLayoutInit()

// 초기화 함수
const initialize = () => {
  setLayout(window.history.state)
}

const completedInit = () => {
  const checkAll = makeArray(document.querySelectorAll('[data-toggle="check-all"]'))
  checkAll.forEach(el => {
    if (!CheckAll.getInstance(el)) {
      return new CheckAll(el)
    }
  })

  const tabList = makeArray(document.querySelectorAll('.c-tab, [data-toggle="tab"]'))
  tabList.forEach(el => {
    if (!Tab.getInstance(el)) {
      return new Tab(el)
    }
  })

  const tooltipList = makeArray(document.querySelectorAll('.c-tooltip__button'))
  tooltipList.forEach(el => {
    if (!Tooltip.getInstance(el)) {
      return new Tooltip(el)
    }
  })

  const formList = makeArray(document.querySelectorAll('.c-form__field'))
  formList.forEach(el => {
    if (!Form.getInstance(el)) {
      if (el.querySelector('.input-date')) {
        return
      }

      return new Form(el)
    }
  })

  const LabelForList = makeArray(document.querySelectorAll('.o-input--radio, .o-input--check, .c-radio-box__input'))
  LabelForList.forEach(el => {
    return new LabelFor(el)
  })

  const selectEls = makeArray(document.querySelectorAll('.listbox > select'))
  selectEls.forEach(el => {
    if (!Dropdown.getInstance(el)) {
      return new Dropdown(el)
    }
  })

  // scrollbar
  const scrollOption = {
    modalBody: {
      scrollOffset: 10
    },
    address: {
      outer: true, // 스크롤 영역이 요소로 지정하여 요소 밖으로 컨테이너 생성 (스크롤바 위치를 컨테이너 안으로 생성)
      parentSelector: '.modal-address', // 스크롤 영역이 밖으로 생성할 때 부모 요소 지정
      paddingSize: 6, // 패팅  추가시 패딩 너비
      scrollOffset: 10, // 스크롤 위치 translate
      scrollSpacing: 2 // 스크롤 100% - 1px
    },
    table: {
      direction: 'horizontal',
      show() {
        if (this._element.querySelector('.c-table__shadow')) {
          return
        }

        const shadowEl = document.createElement('div')
        const childEl = this._element.querySelector('scrollbar ')
        shadowEl.classList.add('c-table__shadow')
        shadowEl.setAttribute('aria-hidden', true)
        this._element.insertBefore(shadowEl, childEl)
      }
    },
    jobSearch: {
      paddingSize: 6,
      scrollOffset: 10,
      scrollSpacing: 20
    }
  }

  // const scrollVertical = makeArray(document.querySelectorAll('[data-scroll="vertical"]'))
  // scrollVertical.forEach(el => {
  //   Scrollbar.interface(el, scrollOption.defaultVertical)
  // })

  const tableScrollEls = makeArray(document.querySelectorAll('.c-table--scroll'))
  tableScrollEls.forEach(el => {
    Scrollbar.interface(el, scrollOption.table)
  })

  const modalEls = makeArray(document.querySelectorAll('.c-modal'))
  modalEls.forEach(el => {
    el.addEventListener('shown.fb.modal', e => {
      const { target } = e
      const modalBody = SelectorEngine.findOne('.c-modal__body', target)
      const address = SelectorEngine.findOne('.modal-address__list', target)
      const tables = makeArray(SelectorEngine.find('.c-table--scroll', target))
      const accordions = makeArray(SelectorEngine.find('.c-accordion__inside', target))
      const jobSearch = makeArray(SelectorEngine.find('.search-list', target))

      Scrollbar.interface(modalBody, scrollOption.modalBody)

      if (address) {
        Scrollbar.interface(address, scrollOption.address)
      }

      tables.forEach(el => {
        setTimeout(() => {
          Scrollbar.interface(el, scrollOption.table)
        }, 200)
      })

      accordions.forEach(el => {
        Scrollbar.interface(el)
      })

      jobSearch.forEach(el => {
        Scrollbar.interface(el, scrollOption.jobSearch)
      })
    })
  })

  const tabEls = makeArray(document.querySelectorAll('.c-tab'))
  tabEls.forEach(el => {
    el.addEventListener('shown.fb.tab', e => {
      const { target, relatedTarget } = e
      const panel = document.getElementById(relatedTarget.getAttribute('aria-controls'))
      const address = SelectorEngine.findOne('.modal-address__list', panel)
      const modalBody = SelectorEngine.parents(target, '.c-modal__body')[0]
      const tables = makeArray(SelectorEngine.find('.c-table--scroll', panel))
      if (modalBody) {
        const data = Scrollbar.getInstance(modalBody)
        if (data) {
          data.dispose()
        }

        Scrollbar.interface(modalBody, scrollOption.modalBody)
      }

      if (address) {
        Scrollbar.interface(address, scrollOption.address)
      }

      tables.forEach(el => {
        Scrollbar.interface(el, scrollOption.table)
      })
    })
  })

  const accordionEls = makeArray(document.querySelectorAll('.c-accordion__inside'))
  accordionEls.forEach(el => {
    const panel = SelectorEngine.parents(el, '.c-accordion__panel')[0]
    const modalBody = SelectorEngine.parents(el, '.c-modal__body')[0]

    EventHandler.on(panel, 'shown.fb.accordion', () => {
      Scrollbar.interface(modalBody, scrollOption.modalBody)
      Scrollbar.interface(el)
    })

    EventHandler.on(panel, 'hidden.fb.accordion', () => {
      Scrollbar.interface(modalBody, scrollOption.modalBody)
    })
  })

  // search-form__input
  const searchFormInput = () => {
    const els = document.querySelectorAll('.search-form__input')
    makeArray(els).forEach(el => {
      if (el.uid) {
        return
      }

      el.uid = getUID('searchInput')
      const input = el.querySelector('input')
      const button = el.querySelector('button')

      input.addEventListener('keyup', e => {
        if (e.target.value) {
          el.classList.add('is-active')
          button.style.display = 'block'
        } else {
          el.classList.remove('is-active')
          button.style.display = 'none'
        }
      })

      button.addEventListener('click', () => {
        el.classList.remove('is-active')
        input.value = ''
        input.focus()
        button.style.display = 'none'
      })
    })
  }

  // ie button effect 방지
  const buttons = makeArray(document.querySelectorAll('button.o-button'))
  buttons.forEach(el => {
    const span = document.createElement('span')
    span.innerHTML = el.innerHTML
    el.innerHTML = ''
    el.appendChild(span)
  })

  // 로그인 다른 인증 수단 // 접근성 추가 작업 필요함.
  const cbtn = document.querySelector('.certification-button')
  const dbtn = document.querySelector('.defferent-certification')

  if (cbtn && dbtn) {
    dbtn.addEventListener('click', () => {
      dbtn.classList.add('d-none')
      cbtn.classList.add('d-flex')
      cbtn.children[0].focus()
    })
    // cbtn.addEventListener('focusout', e => {
    //   e.preventDefault()
    //   e.stopPropagation()
    //   if (!e.relatedTarget || (e.relatedTarget && !e.relatedTarget.classList.contains('link'))) {
    //     dbtn.classList.remove('d-none')
    //     cbtn.classList.remove('d-flex')
    //   }
    // })
  }

  // 임시처리

  // 카드 타입3 버튼 클릭시
  const productCardOpen = () => {
    const els = document.querySelectorAll('.product-accordion__button--more')
    makeArray(els).forEach(el => {
      if (el.uidEvent) {
        return
      }

      EventHandler.on(el, 'click.productCard', e => {
        const target = SelectorEngine.parents(e.target, '.product-accordion')[0]
        target.classList.toggle('is-active')
      })
    })
  }

  const selectEmail = () => {
    const el = SelectorEngine.findOne('select[title="이메일"]')

    if (!el) {
      return
    }

    const parent = SelectorEngine.parents(el, '.c-form__field')[0]
    const sibling1 = SelectorEngine.next(parent, '.c-form__field')[0]
    const sibling2 = SelectorEngine.next(sibling1, '.c-form__field')[0]
    const sibling2Input = SelectorEngine.findOne('.c-form__text', sibling2)

    el.addEventListener('change', e => {
      if (e.target.value === '') {
        sibling1.style.display = ''
        sibling2.style.display = 'none'
      } else {
        sibling1.style.display = 'none'
        sibling2.style.display = ''
        sibling2Input.textContent = e.target.value
      }
    })
  }

  const datePicker = () => {
    const datePickerEls = makeArray(document.querySelectorAll('.flatpickr-calendar'))
    datePickerEls.forEach(el => {
      el.parentNode.removeChild(el)
    })

    const inputDate = document.querySelectorAll('.input-date')
    makeArray(inputDate).forEach(el => {
      const inputDateField = SelectorEngine.closest(el, '.c-form__field')
      if (inputDateField) {
        inputDateField.classList.add('has-value')
        el.querySelector('input').setAttribute('placeholder', 'YYYY-MM-DD')
      }
    })

    const dateTrigger = document.querySelectorAll('.input-date__button')

    makeArray(dateTrigger).forEach(el => {
      el.addEventListener('click', e => e.preventDefault())
    })

    // eslint-disable-next-line no-undef
    flatpickr(dateTrigger, {
      onClose(selectedDates, dateStr, instance) {
        instance.element.previousElementSibling.value = dateStr
      }
    })
  }

  productCardOpen()
  searchFormInput()
  selectEmail()
  datePicker()
}

export default {
  Alert,
  Collapse,
  Modal,
  Tab,
  Tooltip,
  Form,
  CheckAll,
  CheckDisabled,
  InputDots,
  Toggle,
  Loading,
  Dropdown,
  Scrollbar,
  ScrollMove,
  initialize,
  gnbNav,
  mainPage
}

window.addEventListener('load', () => {
  alarmToggle()
  allMenuToggle()
  menuSearchToggle()
  menuSearchFormInput()
  headerLoginToggle()
  initialize()
  skipNavigation()
})
