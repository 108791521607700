import { makeArray } from './util/index.js'
import Data from './dom/data.js'
import EventHandler from './dom/event-handler.js'
import SelectorEngine from './dom/selector-engine.js'
import Manipulator from './dom/manipulator.js'

const NAME = 'dropdown'
const VERSION = '1.0.0'
const DATA_KEY = 'fb.dropdown'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'
const ESCAPE_KEYCODE = 27 // KeyboardEvent.which value for Escape (Esc) key
const SPACE_KEYCODE = 32 // KeyboardEvent.which value for space key
const TAB_KEYCODE = 9 // KeyboardEvent.which value for tab key
const ARROW_UP_KEYCODE = 38 // KeyboardEvent.which value for up arrow key
const ARROW_DOWN_KEYCODE = 40 // KeyboardEvent.which value for down arrow key
const RIGHT_MOUSE_BUTTON_WHICH = 3 // MouseEvent.which value for the right button (assuming a right-handed mouse)
const HOME_KEYCODE = 36
const END_KEYCODE = 35
const PAGEUP_KEYCODE = 33
const PAGEDOWN_KEYCODE = 34

const REGEXP_KEYDOWN = new RegExp(`${ARROW_UP_KEYCODE}|${ARROW_DOWN_KEYCODE}|${ESCAPE_KEYCODE}|${HOME_KEYCODE}|${END_KEYCODE}|${PAGEUP_KEYCODE}|${PAGEDOWN_KEYCODE}`)

const ClassName = {
  SHOW: 'is-active'
}

const Selector = {
  DATA_TOGGLE: '.listbox > button',
  MENU: '.listbox-panel',
  MENU_ITEMS: '.listbox-panel__item'
}

const Default = {}

const DefaultType = {
  // style: 'string'
}
const Event = {
  HIDE: `hide${EVENT_KEY}`,
  HIDDEN: `hidden${EVENT_KEY}`,
  SHOW: `show${EVENT_KEY}`,
  SHOWN: `shown${EVENT_KEY}`,
  CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`,
  KEYDOWN_DATA_API: `keydown${EVENT_KEY}${DATA_API_KEY}`,
  KEYUP_DATA_API: `keyup${EVENT_KEY}${DATA_API_KEY}`
}

class Dropdown {
  constructor(element, config) {
    this._element = element
    this._options = makeArray(SelectorEngine.find('option', this._element))
    this._config = this._getConfig(config)
    this._parent = SelectorEngine.parents(this._element, '.listbox')[0]
    this._form = SelectorEngine.parents(this._element, '.c-form__field')[0]

    Data.setData(element, DATA_KEY, this)

    this.initElement()
    if (!this._element.disabled) {
      this._addEventListeners()
    }
  }

  toggle() {
    const isActive = this._parent.classList.contains(ClassName.SHOW)

    Dropdown.clearMenus()

    if (isActive) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    this._parent.classList.add('is-active')
    this._button.setAttribute('aria-expanded', true)

    const items = makeArray(SelectorEngine.find(Selector.MENU_ITEMS, this._parent))
    const selectedItem = SelectorEngine.findOne('[aria-selected="true"]', this._parent)
    const index = items.indexOf(selectedItem)
    if (index > -1) {
      items[index].focus()
    }

    const relatedTarget = {
      relatedTarget: this._element
    }

    EventHandler.trigger(this._parent, Event.SHOW, relatedTarget)
    EventHandler.trigger(this._button, Event.SHOWN, relatedTarget)
  }

  hide() {
    this._parent.classList.remove('is-active')
    this._button.setAttribute('aria-expanded', false)
    this._button.focus()

    const relatedTarget = {
      relatedTarget: this._element
    }

    EventHandler.trigger(this._parent, Event.HIDE, relatedTarget)
    EventHandler.trigger(this._parent, Event.HIDDEN, relatedTarget)
  }

  selectedList(e) {
    const { target } = e
    const items = makeArray(SelectorEngine.find('li', this._panel))
    this._button.textContent = target.textContent
    this._panel.setAttribute('aria-activedescendant', target.textContent)
    let index = 0

    items.forEach((el, i) => {
      if (el === target) {
        el.setAttribute('aria-selected', true)
        index = i
      } else {
        el.removeAttribute('aria-selected')
      }
    })

    this._options[index].selected = true

    const evt = document.createEvent('HTMLEvents')

    evt.initEvent('change', true, true)
    this._element.dispatchEvent(evt)
    this.hide()
  }

  _addEventListeners() {
    EventHandler.on(this._button, 'click', e => {
      e.preventDefault()
      e.stopPropagation()
      this.toggle()
    })

    EventHandler.on(this._panel, 'click', 'li', e => {
      this.selectedList(e)
    })

    EventHandler.on(this._panel, 'keypress', 'li', e => {
      e.preventDefault()
      this.selectedList(e)
    })
  }

  _removeEventListeners() {
    EventHandler.off(this._button, 'click')
    EventHandler.off(this._panel, 'click')
    EventHandler.off(this._panel, 'keypress')
  }

  initElement() {
    this._element.style.display = 'none'
    const title = this._element.getAttribute('title')
    const { selectedIndex } = this._element
    const { textContent } = selectedIndex < 0 ? this._options[0] : this._options[selectedIndex]
    const srOnly = document.createElement('span')
    srOnly.classList.add('sr-only')
    srOnly.textContent = `${title} 열기`

    const button = document.createElement('button')
    button.setAttribute('type', 'button')
    button.setAttribute('aria-haspopup', 'listbox')
    button.setAttribute('aria-expanded', false)
    button.classList.add('listbox__button')
    if (this._element.disabled) {
      button.setAttribute('aria-disabled', true)
      button.disabled = true
      this._parent.classList.add('is-disabled')
    }

    button.textContent = textContent
    button.appendChild(srOnly)

    const panel = document.createElement('ul')
    panel.classList.add('listbox-panel')
    panel.setAttribute('role', 'listbox')
    panel.setAttribute('aria-lable', title)
    panel.setAttribute('aria-activedescendant', textContent)

    const list = document.createElement('ul')
    list.classList.add('list-panel__list')

    let items = ''
    this._options.forEach(el => {
      if (el.textContent === textContent) {
        items += `<li class="listbox-panel__item" role="option" tabindex="0" aria-selected="true">${el.textContent}</li>`
      } else {
        items += `<li class="listbox-panel__item" role="option" tabindex="0">${el.textContent}</li>`
      }
    })

    panel.innerHTML = items

    this._parent.appendChild(button)
    this._parent.appendChild(panel)
    this._button = button
    this._panel = panel
  }

  dispose() {
    // this._element.removeAttribute('style')
    // const childEls = this.scrollInner.children
    // const originalEls = makeArray(childEls).map(el => el)
    // this._element.removeChild(this.scrollInner)
    // this._element.removeChild(this.scrollbar)
    // makeArray(originalEls).forEach(el => {
    //   this._element.appendChild(el)
    // })

    // Data.removeData(this._element, this.constructor.DATA_KEY)
    // EventHandler.off(this.scrollInner, this.constructor.EVENT_KEY)
    // EventHandler.off(this.scrollOuter, this.constructor.EVENT_KEY)
    // EventHandler.off(window, this.constructor.EVENT_KEY)
  }

  changeDisabled(state = true) {
    this._element.disabled = state

    if (this._element.disabled) {
      this._parent.classList.add('is-disabled')
      this._button.setAttribute('aria-disabled', true)
      this._button.disabled = true
      if (this._form) {
        this._form.classList.add('is-disabled')
      }

      this._removeEventListeners()
    } else {
      this._parent.classList.remove('is-disabled')
      this._button.setAttribute('aria-disabled', false)
      this._button.disabled = false
      if (this._form) {
        this._form.classList.remove('is-disabled')
      }

      this._addEventListeners()
    }
  }

  // getter
  static get NAME() {
    return NAME
  }

  static get VERSION() {
    return VERSION
  }

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  // private
  _getConfig(config) {
    const dataAttributes = Manipulator.getDataAttributes(this._element)

    config = {
      ...this.constructor.Default,
      ...dataAttributes,
      ...typeof config === 'object' && config ? config : {}
    }

    return config
  }

  // Static

  static interface(element, config) {
    let data = Data.getData(element, DATA_KEY)
    const _config = typeof config === 'object' ? config : null

    if (!data) {
      data = new Dropdown(element, _config)
    }

    if (typeof config === 'string') {
      if (typeof data[config] === 'undefined') {
        throw new TypeError(`No method named "${config}"`)
      }

      data[config]()
    }
  }

  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }

  /* eslint-disable complexity */
  static dataApiKeydownHandler(event) {
    const { target, which, altKey } = event

    if (!REGEXP_KEYDOWN.test(which)) {
      return
    }

    event.preventDefault()
    event.stopPropagation()

    const parent = SelectorEngine.parents(target, '.listbox')[0]
    const dropdown = SelectorEngine.findOne('select', parent)
    const button = SelectorEngine.findOne('button', parent)

    if (dropdown.disabled) {
      return
    }

    const isActive = parent.classList.contains(ClassName.SHOW)

    if (isActive && (which === ESCAPE_KEYCODE || which === SPACE_KEYCODE)) {
      if (which === ESCAPE_KEYCODE) {
        SelectorEngine.findOne(Selector.DATA_TOGGLE, parent).focus()
      }

      Dropdown.clearMenus()
      return
    }

    if (altKey && (which === ARROW_DOWN_KEYCODE || which === ARROW_UP_KEYCODE)) {
      EventHandler.trigger(button, 'click')
      return
    }

    const items = makeArray(SelectorEngine.find(Selector.MENU_ITEMS, parent))
    const selectedItem = SelectorEngine.findOne('[aria-selected="true"]', parent)
    if (!items.length) {
      return
    }

    let index = isActive ? items.indexOf(target) : items.indexOf(selectedItem)
    const lastIndex = items.length - 1

    if (which === ARROW_UP_KEYCODE && index > 0) { // Up
      index--
    }

    if (which === ARROW_DOWN_KEYCODE && index < lastIndex) { // Down
      index++
    }

    if (which === HOME_KEYCODE && index !== 0) {
      index = 0
    }

    if (which === END_KEYCODE && index !== lastIndex) {
      index = lastIndex
    }

    if (which === PAGEUP_KEYCODE && index > 0) {
      index = isActive ? 0 : index - 3
    }

    if (which === PAGEDOWN_KEYCODE && index < lastIndex) {
      index = isActive ? lastIndex : index + 3
    }

    if (index < 0) {
      index = 0
    }

    if (index > lastIndex) {
      index = lastIndex
    }

    if (isActive) {
      items[index].focus()
    } else {
      EventHandler.trigger(items[index], 'keypress')
    }
  }

  static clearMenus(event) {
    if (event && (event.which === RIGHT_MOUSE_BUTTON_WHICH ||
      (event.type === 'keyup' && event.which !== TAB_KEYCODE))) {
      return
    }

    const toggles = makeArray(SelectorEngine.find('.listbox > select'))

    for (let i = 0, len = toggles.length; i < len; i++) {
      const parent = toggles[i].parentNode
      const context = Data.getData(toggles[i], DATA_KEY)
      const relatedTarget = {
        relatedTarget: toggles[i]
      }

      if (event && event.type === 'click') {
        relatedTarget.clickEvent = event
      }

      if (!context) {
        continue
      }

      if (!parent.classList.contains('is-active')) {
        continue
      }

      if (event && ((event.type === 'click' &&
          /input|textarea/i.test(event.target.tagName)) ||
          (event.type === 'keyup' && event.which === TAB_KEYCODE)) &&
          parent.contains(event.target)) {
        continue
      }

      parent.classList.remove('is-active')

      EventHandler.trigger(parent, Event.HIDE, relatedTarget)
      EventHandler.trigger(parent, Event.HIDDEN, relatedTarget)
    }
  }
}

EventHandler.on(document, Event.CLICK_DATA_API, Dropdown.clearMenus)
EventHandler.on(document, Event.KEYUP_DATA_API, Dropdown.clearMenus)
EventHandler.on(document, Event.KEYDOWN_DATA_API, Selector.DATA_TOGGLE, Dropdown.dataApiKeydownHandler)
EventHandler.on(document, Event.KEYDOWN_DATA_API, Selector.MENU, Dropdown.dataApiKeydownHandler)

export default Dropdown
