// import { getjQuery } from './util/index.js'
import Data from './dom/data.js'
import SelectorEngine from './dom/selector-engine.js'
import { getTransitionDurationFromElement, emulateTransitionEnd } from './util/index.js'
import EventHandler from './dom/event-handler.js'

const NAME = 'loading'
const VERSION = '1.0.0'
const DATA_KEY = `fb.${NAME}`
const EVENT_KEY = `.${DATA_KEY}`

const ClassName = {
  BACKDROP: 'o-dimmed',
  BACKDROPSHOW: 'o-dimmed--transition',
  LOADING: 'c-loading'
}

Event = {
  SHOW: `show${EVENT_KEY}`,
  HIDE: `hide${EVENT_KEY}`
}

const Default = {
  startMessage: '로딩이 시작되었습니다.',
  endMessage: '로딩이 종료되었습니다.'
}

const Template = `
<div class="c-loading__bars" aria-hidden="true">
  <div class="c-loading__bar c-loading__bar--1"></div>
  <div class="c-loading__bar c-loading__bar--2"></div>
  <div class="c-loading__bar c-loading__bar--3"></div>
  <div class="c-loading__bar c-loading__bar--4"></div>
  <div class="c-loading__bar c-loading__bar--5"></div>
  <div class="c-loading__bar c-loading__bar--6"></div>
  <div class="c-loading__bar c-loading__bar--7"></div>
  <div class="c-loading__bar c-loading__bar--8"></div>
  <div class="c-loading__bar c-loading__bar--9"></div>
  <div class="c-loading__bar c-loading__bar--10"></div>
  <div class="c-loading__bar c-loading__bar--11"></div>
  <div class="c-loading__bar c-loading__bar--12"></div>
</div>
<div id="loading-start" aria-live="assertive"></div>
<div id="loading-end" aria-live="assertive"></div>
`
class Loading {
  constructor(config) {
    this._config = this._getConfig(config)
    this.isShow = false
    this._element = document.createElement('div')
    this._element.setAttribute('id', 'loading')
    this.count = 1

    Data.setData(this._element, DATA_KEY, this)

    if (this._config.open) {
      this.show()
    }
  }

  // Getters

  static get VERSION() {
    return VERSION
  }

  static get DEFAULT() {
    return Default
  }

  show() {
    this.count += 1
    if (this.isShow) {
      return
    }

    if (document.querySelector('.l-head')) {
      document.querySelector('.l-head').style.zIndex = '200'
    }

    this.isShow = true
    this._showBackdrop(() => this._showElement())
    if (typeof this._config.show === 'function') {
      this._config.show(this)
    }
  }

  hide() {
    this.count -= 1
    if (!this.isShow) {
      return
    }

    setTimeout(() => {
      if (!document.querySelector('#loading')) {
        return
      }

      if (document.querySelector('.l-head')) {
        document.querySelector('.l-head').style.zIndex = '';
      }

      this._element.parentNode.removeChild(this._element)

      if (this._backdrop) {
        this._backdrop.parentNode.removeChild(this._backdrop)
      }

      this.isShow = false
      if (typeof this._config.hide === 'function') {
        this._config.hide(this)
      }
    }, 1000)
  }

  dispose() {
    Data.removeData(this._element, DATA_KEY)
    this._element = null
  }

  // private
  _message() {
    const { startMessage, endMessage } = this._config
    const start = SelectorEngine.findOne('#loading-start', this._element)
    const end = SelectorEngine.findOne('#loading-end', this._element)
    start.textContent = startMessage
    end.textContent = endMessage
  }

  _showElement() {
    this._element.className = ClassName.LOADING
    this._element.innerHTML = Template
    this._message()
    document.body.appendChild(this._element)
  }

  _showBackdrop(callback) {
    if (!document.querySelector(`.${ClassName.BACKDROPSHOW}`)) {
      this._backdrop = document.createElement('div')
      this._backdrop.className = ClassName.BACKDROP
      this._backdrop.classList.add(ClassName.BACKDROPSHOW)
      this._backdrop.setAttribute('aria-hidden', 'true')
      document.body.appendChild(this._backdrop)

      setTimeout(() => {
        this._backdrop.classList.add('is-active')
        callback()
      }, 0)
    }
  }

  _getConfig(config) {
    config = {
      ...this.constructor.DEFAULT,
      ...typeof config === 'object' && config ? config : {}
    }

    return config
  }

  // Static
  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }
}

export default Loading
