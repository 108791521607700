import { makeArray, isElement } from './util/index.js'
import Data from './dom/data.js'
import EventHandler from './dom/event-handler.js'
import Manipulator from './dom/manipulator.js'
import SelectorEngine from './dom/selector-engine.js'

const DATA_KEY = 'fb.check-disabled'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Default = {
  parent: '.product-card'
}

const Event = {
  CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`
}

const Selector = {
  DATA_TOGGLE: '[data-toggle="check-disabled"]'
}

class CheckDisabled {
  constructor(element, config) {
    this._element = element
    this._config = this._getConfig(config)
    this._name = this._element.name
    const parent = this._parents(this._element)
    this._config.userName = SelectorEngine.findOne('.product-card__name-ko', parent) || null

    Data.setData(element, DATA_KEY, this)
  }

  toggle() {
    if (this._element.checked) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    const parent = this._parents(this._element)
    const formEls = this._formEls(this._element, parent)

    if (this._config.userName) {
      this._config.userName.classList.add('co-blue')
    }

    formEls.forEach(el => {
      const formField = SelectorEngine.parents(el, '.c-form__field')

      if (isElement(formField)) {
        formField[0].classList.remove('is-disabled')
      }

      if (this._config.hidden) {
        el.removeAttribute('hidden')
      } else {
        el.removeAttribute('disabled')
      }
    })

    const siblings = this._siblings()
    siblings.forEach(el => {
      this.hide(el)
    })
  }

  hide(element = this._element) {
    const parent = this._parents(element)
    const formEls = this._formEls(element, parent)

    if (this._config.userName) {
      this._config.userName.classList.remove('co-blue')
    }

    formEls.forEach(el => {
      const formField = SelectorEngine.parents(el, '.c-form__field')

      if (isElement(formField)) {
        formField[0].classList.add('is-disabled')
      }

      if (this._config.hidden) {
        el.setAttribute('hidden', '')
      } else {
        el.setAttribute('disabled', '')
      }
    })
  }

  _siblings() {
    return makeArray(SelectorEngine.find(`[name="${this._name}"]`)).filter(el => {
      return el !== this._element
    })
  }

  _parents(element) {
    return SelectorEngine.closest(element, this._config.parent)
  }

  _formEls(element, parent) {
    return makeArray(SelectorEngine.find('input, select, textarea, button', parent)).filter(el => {
      return el !== element
    })
  }

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...typeof config === 'object' && config ? config : {}
    }

    return config
  }

  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }
}

EventHandler.on(document, Event.CLICK_DATA_API, Selector.DATA_TOGGLE, e => {
  let data = Data.getData(e.target, DATA_KEY)

  if (data) {
    data = CheckDisabled.getInstance(e.target)
  } else {
    data = new CheckDisabled(e.target)
  }

  data.toggle()
})

export default CheckDisabled

