import { makeArray, isElement } from './util/index.js'
import Data from './dom/data.js'
import EventHandler from './dom/event-handler.js'
import Manipulator from './dom/manipulator.js'
import SelectorEngine from './dom/selector-engine.js'

const DATA_KEY = 'fb.check-all'
const EVENT_KEY = `.${DATA_KEY}`
const DATA_API_KEY = '.data-api'

const Default = {
  parent: '.agree-check',
  child: '.o-input--check'
}

const Event = {
  CLICK: `click${EVENT_KEY}`,
  CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`
}

class CheckAll {
  constructor(element, config) {
    this._element = element
    this._config = this._getConfig(config)

    if (!isElement(SelectorEngine.find(this._config.parent))) {
      return
    }

    Data.setData(element, DATA_KEY, this)

    this._parnet = SelectorEngine.closest(this._element, this._config.parent)
    if (this._config.target) {
      this._child = makeArray(SelectorEngine.find(this._config.target))
    } else {
      this._child = makeArray(SelectorEngine.find(this._config.child, this._parnet))
    }

    this.toggle()
    this.addEventListener()
  }

  addEventListener() {
    EventHandler.on(this._element, Event.CLICK_DATA_API, () => {
      this.toggle()
    })

    this._child.forEach(el => {
      EventHandler.on(el, Event.CLICK, false, e => this.isChildChecked(e))
    })
  }

  toggle() {
    if (this._element.checked) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    this.checkState(0)
  }

  hide() {
    this.checkState(1)
  }

  checkState(state) {
    this._child.forEach(c => {
      if (state) {
        c.checked = false
      } else {
        c.checked = true
      }
    })
  }

  isChildChecked() {
    let checked = true
    this._child.forEach(e => {
      if (this._element === e) {
        return
      }

      if (!e.checked) {
        checked = false
      }
    })

    this._element.checked = checked
  }

  dispose() {
    Data.removeData(this._element, DATA_KEY)
    EventHandler.off(this._element, Event.CLICK_DATA_API)
    this._child.forEach(el => {
      EventHandler.off(el, Event.CLICK)
    })
    this._element = null
  }

  _getConfig(config) {
    config = {
      ...Default,
      ...Manipulator.getDataAttributes(this._element),
      ...typeof config === 'object' && config ? config : {}
    }

    return config
  }

  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }
}

export default CheckAll
